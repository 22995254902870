.alert-container {
  margin: 0 auto;
  max-width: 800px;
  background-color: #79b72a;
  border-radius: 3px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
  .external-alert {
    padding: 10px;
    color: white;
    text-align: center;
  }
}
